@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
main#reach-skip-nav:focus-visible {
  outline: 0;
}

.layout-no-sidebars {
  overflow-x: hidden;
}

.layout-main-wrapper {
  display: grid;
  align-items: start;
  grid-template-columns: minmax(0,1fr);

  @include media-breakpoint-up(lg) {
    grid-template-columns: 23.5% minmax(0,1fr);

    &.layout-no-sidebar {
      grid-template-columns: 0 minmax(0,1fr);

      .main-content {
        padding-left: 0;
      }
    }
  }
}

.main-content {
  padding-bottom: $spacer*2;
	outline-style: none;

	&:focus {
		outline-style: none;
	}
  
  @include media-breakpoint-up(lg) {
    grid-column: 2;
    padding-left: $spacer*3;
  }
}  

.main-sidebar {
  width: 99.9vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  @include media-breakpoint-up(lg) {
    width: 100%;
    left: 0;
    margin-left: 0;
    margin-top: -$spacer*2;
  }
}

.container {
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// skip link
:root {
  --reach-skip-nav: 1;
}

[data-reach-skip-nav-link] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

[data-reach-skip-nav-link]:focus {
  padding: $spacer*0.5 $spacer;
  position: relative;
  margin: 0.2%;
  text-align: center;
  width: 99.6%;
  background: white;
  z-index: 1;
  height: auto;
  clip: auto;
  display: block;
}
