@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.breadcrumb.breadcrumb-enterprise {
  background-color: #fff;
  font-size: 0.9rem;
  padding: 0;
  margin: $spacer 0;
  font-family: var(--sp-font-secondary);

  // Home icon
  svg {
    margin-bottom: 0.3em;
    width: 1.2em;
    height: 1.2em;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: "\203A";

    // on desktop, change the orientation of the caret
    @include media-breakpoint-up(md) {
      content: "\203A";
    }
  }

  // on mobile, display only the immediate parent
  // otherwise display the whole trail
  .breadcrumb-item {
    display: none;

    &:first-of-type {
      display: none;
    }

    &:nth-last-of-type(2),
    &:last-of-type {
      display: inline;
    }

    @include media-breakpoint-up(md) {
      display: inline;

      &:first-of-type {
        display: inline;
      }
    }
  }
}
