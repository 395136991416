@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.imagecard {
  border-bottom: none;
  border-top: solid 8px var(--sp-color-primary);
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.5);
  transition: transform .2s;
  margin: $spacer*2 0;

  &:hover {
    box-shadow: 0 1px 32px 0 rgba(0, 0, 0, 0.8);

    @include media-breakpoint-up(md) {
      transform: scale(1.05);
    }
  }

  &__content {
    padding: $spacer*1.5;
    background-color: var(--sp-color-primary);
    color: $white;
    height: 100%;

    @include media-breakpoint-up(md) {
      box-shadow: none;
    }

    p {
      margin: 0 0 24px;
    }

    a {
      color: $white;
      text-underline-offset: 2px;

      &:hover {
        color: $white;
        background: transparent;
        text-decoration: none;

        &:after {
          background: transparent !important;
        }
      }
    }

    .button {
      margin: 0;
      text-decoration: none;
      &:hover {
        color: $white;
      }
    }
  }

  &__heading {
    color: $white;
    margin: 0 0 24px;
  }

  &__link {
    color: $white;
  }

  &__image {
    overflow: hidden;
    background-color: $white;

    .image {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100% !important;
      max-height: 100%;

      img {
        position: relative;
      }
    }
  }

  // modifiers
  &--even {
    border-top-color: var(--sp-color-secondary);
    & .imagecard__content {
      background-color: var(--sp-color-secondary);
    }
  }
}
