@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.related-content {
  &-container {
    margin-top: $spacer * 3;
    font-family: var(--sp-font-secondary);

    @include media-breakpoint-down(sm) {
      margin-top: $spacer * 2;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        list-style: none;
        padding: $spacer * 1.5 0;
        width: 100%;
        border-bottom: 1px solid var(--sp-color-neutral-accent-one);

        @include media-breakpoint-up(md) {
          display: grid;
          grid-template-columns: auto 1fr;
        }

        img {
          margin-right: $spacer * 2;
          margin-bottom: $spacer;
          width: 300px;
          height: 185px;
          object-fit: cover;
        }

        &:last-of-type {
          border-bottom: none;
        }

        .date {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.71;
          color: var(--sp-color-text);
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }
  }

  &-item-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6 !important;
    color: var(--sp-color-secondary);
    margin-bottom: 20px;
  }
}
