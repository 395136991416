@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__publicationlist {
    ul {
        li {
            margin-bottom: $spacer;
            a {
                font-weight: bold;
            }
        }
    }
}