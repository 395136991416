@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.meta-box {
  box-shadow: -2.2em 2.2em var(--sp-color-secondary);
  border: 8px solid #e5e5e5;
  overflow-wrap: break-word;
  padding: $spacer;

  h4 {
    margin: 0 0 16px;
  }

  .date,
  p {
    font-style: italic;
  }

  .field-container {
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 8px;
    margin-bottom: 1em;
    word-break: break-word;

    p {
      margin: 0;
    }

    svg {
      margin-right: 5px;
      color: var(--sp-color-secondary);
      width: 24px;
      height: 24px;
    }

    .date,
    .time {
      max-width: 210px;
      line-height: 24px;
    }

    .date.date--full-width {
      max-width: 100%;
    }
  }

  &.news-meta {
    margin-bottom: 50px;
  }

  &.profile-meta,
  &.event-meta {
    box-shadow: 2.2em 2.2em var(--sp-color-secondary);

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer * 2;
    }
  }
}
