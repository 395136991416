@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__imagecardrow {
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 4;
  padding-bottom: $spacer * 2;
  position: relative;
  font-size: 0.9rem;

  @include media-breakpoint-down(lg) {
    overflow-x: hidden;
  }

  &::after {
    content: "";
    width: 100%;
    height: 70%;
    position: absolute;
    top: 30%;
    background-color: var(--sp-color-neutral-accent-one);
    z-index: -1;
  }

  .heading {
    @include media-breakpoint-up(md) {
      margin-bottom: $spacer;
    }
  }

  .row {
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }
}

// If this PB is used on a page with the sidebar, offset the background
.layout-with-sidebar .paragraph__imagecardrow {
  &::after {
    margin-left: 3em;
    margin-right: 0;
    // width: 100vw;
  }
}
