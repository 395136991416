@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.sidebar-menu {
  font-family: var(--sp-font-primary);
  padding: $spacer*0.33 $spacer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-bottom: 4px solid $yellow;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;   

  @include media-breakpoint-up(lg) {
    margin-bottom: 1em;
    padding: $spacer*1.5;
    background-color: $white;
  }
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-nav {
    width: 100%;
  }

  .navbar-collapse,
  .navbar-toggler {
    margin: 0 auto;
    padding: 0 $spacer*0.5;
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint) {
        max-width: $container-max-width;
        padding: 0 $spacer;
      }  
    } 
  }

  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin: 0;
      padding: 0;
    } 
  }

  .has-dropdown > .nav-link {
    white-space: normal;
    display: flex;
    align-items: center;

    &:after {
      border: 0;
      content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2L10.59 0.589996L6 5.17L1.41 0.589996L-6.16331e-08 2L6 8L12 2Z' fill='%23056CA1'/%3E%3C/svg%3E");
      margin-left: auto;
      margin-right: 0.5rem;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  .has-dropdown .nav-link.dropdown-toggle:after {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .navbar-toggler {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-top: $spacer;
    padding-bottom: $spacer;
    text-align: left;
    color: var(--sp-color-primary);
    border: none;
    background: url("../../../../images/minus_icon_blue.svg") right center
      no-repeat;
    background-size: 24px 24px;

    &.collapsed {
      background: url("../../../../images/plus_icon_blue.svg") right center
        no-repeat;
			background-size: 24px 24px;	
      @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
      padding-left: 0;
      padding-right: 0;
      }
    }

    img.navbar-toggle-icon {
      float: right;
    }
  }

  .navbar-toggle-word {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.71;
    color: var(--sp-color-secondary);
    font-family: var(--sp-font-secondary);
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    margin-bottom: 0;

    .nav-item {
      border-bottom: 1px solid var(--sp-color-neutral-accent-one);
      padding: 0.5rem 0;

      &:last-of-type {
        border: none;
      }
    }

    &.dropdown {
      padding-left: $spacer*0.8;
			padding-top: $spacer;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
			}
			
      .nav-item {
        border-bottom: 0;
      }
    }

    .nav-item {
      padding: 0.2em 0;
      width: 100%;

      &:last-of-type {
        border: none;
      }

      &.top-level {
        padding-top: 0;
        border-bottom: 0;

        .nav-link {
          padding-top: 0;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.5;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $shale-blue;
        }
      }

      .nav-link {
        font-family: var(--sp-font-secondary);
        font-weight: 600;
        font-size: 0.9rem;
        color: $dark-gray;
        text-decoration: none;
        padding: 5px 0;

        &.active, &[aria-current] {
          color: var(--sp-color-secondary) !important;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
