@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
// Google search results styleing overwrites
// CSE documentation - @https://developers.google.com/custom-search/docs/element

#___gcse_0 {
	overflow: hidden;
	width: 100%;
}

.gsc-control-cse {
	padding: 0 !important;
}

.gsc-control-cse .gs-result,
.gsc-control-cse .gs-webResult {
	font-size: 1.1rem;
	padding: $spacer*0.5 0;
	font-family: var(--sp-font-primary);

	a.gs-title,
	.gs-title,
	.gs-title b {
		color: var(--sp-color-link) !important;
		font-family: var(--sp-font-secondary);
		font-size: 1.2rem;
		font-weight: 600;
	}

	.gs-visibleUrl {
		color: $dark-gray;
		font-size: 1rem;
		font-style: italic;
	}

	.gs-snippet, 
	.gs-fileFormatType {
		font-family: var(--sp-font-primary);
	}

	.gsc-table-result {
		font-size: 1rem;
	}	

	.gs-web-image-box, 
	.gs-promotion-image-box {
    margin: 6px $spacer 0 0;
  }	

	.gs-image, 
	.gs-promotion-image {
		border: none;
	}
}

// search form
table.gsc-search-box,
table.gsc-above-wrapper-area-container{
	margin: $spacer 0;
	height: 48px;

	td, tr, th, tr:nth-child(odd), tr:nth-child(even) {
		background-color: transparent;
		padding: 0;
		border-bottom: none;
		border-right: none;
	}
}

.gsc-control-cse {
	.gsc-search-button-v2, 
	.gsc-search-button-v2:hover, 
	.gsc-search-button-v2:focus {
		height: 44px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding: 0.32rem 0.75rem;
		border: 4px solid var(--sp-color-secondary);
		background-color: $white;
		overflow: hidden;

		svg {
			width: 20px;
			height: 20px;
			fill: var(--sp-color-primary) !important;
		}
	}
}

td.gsc-input {
	width: 100%;
}

table.gsc-input {
	margin-bottom: 0;
	height: 44px;
}

.gsc-input-box, 
.gsc-input-box-hover, 
.gsc-input-box-focus {
	border-radius: 0;
	border: solid 1px $between-med-light-gray !important;
	height: 44px;
}

input.gsc-input,
input.gsc-input:focus {
	padding: 0 !important;
	background: none !important;
	margin: 5px 10px !important;
}

// clear search link
.gsst_a .gscb_a {
	color: $medium-gray !important;
	cursor: pointer;
	padding-top: 5px;
	height: 36px;
}		

// search button
td.gsc-search-button {
	width: 100px;
	padding-left: $spacer;
	height: 44px;

	@include media-breakpoint-up(lg) {
		width: 10%;
	}
}

.gsc-above-wrapper-area,
table.gsc-above-wrapper-area-container {
	border: none;
	padding: 0 !important;
	height: auto;
}

// individual result item
.gsc-webResult .gsc-result {
	padding-top: $spacer;
	padding-bottom: $spacer;
}

// pagination
.gsc-results .gsc-cursor-box {
	margin-top: $spacer*2;
	border-radius: 0;

	.gsc-cursor-page {
		padding: 2px 3px;
		margin-left: 10px;
		color: var(--sp-color-secondary) !important;
		font-weight: 600;
		font-size: 1rem;
		font-family: var(--sp-font-secondary);
		height: 32px;
		width: 32px;
		text-align: center;
		display: inline-block !important;

		// current page indicator
		&.gsc-cursor-current-page {
			background-color: var(--sp-color-primary);
			border: solid 1px var(--sp-color-primary);
			border-radius: 50%;
			color: $white !important;
		}
	}
}

.gs-web-image-box .gs-image, 
.gs-promotion-image-box .gs-promotion-image {
	border: none;
	margin-right: $spacer;
}

// hide branding, search on Google link, order by dropdown
.gcsc-more-maybe-branding-root,
.gcsc-find-more-on-google-root,
.gcsc-branding,
.gcsc-find-more-on-google-branding,
.gsc-orderby {
	display: none;
}
