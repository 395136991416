@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.navbar-nav .actionmenu li a.nav-link {
  background: var(--sp-color-menu-action-background);
  color: var(--sp-color-menu-action-link);
  padding: $spacer;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover, &.active {
    background: var(--sp-color-menu-action-background-hover);
    color: var(--sp-color-menu-action-link);
  }

  @include media-breakpoint-up(lg) {
    padding: 8px $spacer;
  }
}

.actionmenu {
  margin-top: $spacer;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-right: $spacer*0.5;
  }

  @include media-breakpoint-down(lg) {
    order: 3;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    // TODO: Does this handle multiple CTAs on mobile?

    li {
      font-family: var(--sp-font-secondary);
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;

      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
