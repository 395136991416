@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.heading {
  color: var(--sp-color-primary);

  &--label {
    font-family: var(--sp-font-secondary);
    font-size: 0.9rem;
    font-weight: 700;
    color: $dark-gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $spacer * 0.5;
  }

  &--hero {
    font-family: var(--sp-font-secondary);
    font-size: 2.5rem;
    font-weight: 600;
    color: $white;
    text-shadow: 0 2px 4px #000000;
    letter-spacing: 1px;
    margin-bottom: $spacer * 0.5;
  }

  &--underline {
    @include font-size(26px);
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--sp-color-neutral-accent-one);
    position: relative;

    &:before {
      background: $yellow;
      height: 4px;
      margin: -4px 0 0;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      width: 200px;
    }
  }

  &--teaser {
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: $spacer * 0.5;
    line-height: 1.3;

    a {
      color: var(--sp-color-link);
    }
  }

  &:hover {
    // background-color: var(--sp-color-button-primary-background-hover);
    // transition: background-color 0.2s ease-out;
    // color: var(--sp-color-button-primary-text-hover);
    text-decoration: none;

    .heading__icon {
      transform: translateX(7px) rotate(180deg);
      transition: all 0.2s ease-out;
    }
  }
  
  &__text + &__icon,
  &__icon + &__text {
    margin-left: 0.5rem;
  }

    &__icon {
    position: relative;
    font-size: 26px;
    transform: rotate(180deg);
    transition: all 0.5s ease-out;
    color:var(--sp-color-secondary);
  }
}