@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

@import "~bootstrap/scss/images";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";

html {
  font-family: $font-sans-serif;
}

body {
  font-family: var(--sp-font-primary);
  line-height: $line-height-base;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--sp-font-h1);
  color: var(--sp-color-primary);
  margin: $spacer 0 $spacer * 0.33;
}

h1 {
  font-weight: 700;
  font-family: var(--sp-font-h1);
  @include font-size(52px);
  line-height: 1.4;

  @include media-breakpoint-down(sm) {
    line-height: 1.4;
    // font-size: 1.5rem;
  }
}

h2 {
  font-family: var(--sp-font-h2);
  font-weight: 300;
  @include font-size(40px);
  margin: $spacer 0 $spacer * 0.5 0;
}

h3 {
  font-family: var(--sp-font-h3);
  font-weight: 600;
  @include font-size(26px);
  line-height: 40px;
  text-transform: uppercase;
}

h4 {
  font-family: var(--sp-font-h4);
  font-weight: 600;
  @include font-size(20px);
  line-height: 32px;
  text-transform: uppercase;
}

h5 {
  font-family: var(--sp-font-h5);
  font-weight: 600;
  @include font-size(20px);
  line-height: 32px;
}

h6 {
  font-family: var(--sp-font-h6);
  font-weight: 600;
  @include font-size(17px);
  line-height: 24px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

figcaption {
  font-family: var(--sp-font-secondary);
  font-size: 0.9rem;
  font-weight: 600;
  padding-top: $spacer * 0.5;
}

.media--type-document + figcaption {
  padding-top: 0;
}

// external links
a[href*="http"]:not([href*="-files"]):not(.hide-icon):not(:has(img)) {
  &::after {
    content: url("./../../images/external-link-icon.svg");
    display: inline-block;
    margin-left: 5px;
    transform: scale(0.9);
    position: relative;
    top: 1.5px;
}

  &:hover::after {
    color: inherit;
    background-color: none;
  }
}

// external links on buttons
a[href*="http"]:not([href*="-files"]):not(.hide-icon).button {
  &::after {
    display: none;
  }

  span::after {
    content: url("./../../images/external-link-icon.svg");
    display: inline-block;
    margin-left: 5px;
  }

  &:hover::after {
    background-color: none;
    color: inherit;
  }
}

// secure links
a[href*="/secure/"]:not(.hide-icon) {
  &::after {
    content: url("./../../images/lock_icon.svg");
    display: inline-block;
    width: 22px;
    height: fit-content;
    top: 4px;
    vertical-align: top;
  }

  &:hover::after {
    color: inherit;
    background-color: none;
  }
}

// secure links on buttons
a[href*="/secure/"]:not(.hide-icon).button {
  &::after {
    display: none;
  }

  span::after {
    content: url("./../../images/lock_icon.svg");
    display: inline-block;
    width: 22px;
    height: fit-content;
    margin-top: 4px;
    vertical-align: top;
  }

  &:hover::after {
    background-color: none;
    color: inherit;
  }
}

main {
  color: var(--sp-color-text);

  a {
    color: var(--sp-color-link);
    &:hover {
      // background-color: var(--sp-color-secondary);
      // transition: background-color 0.2s ease-out;
      // color: $white;
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading {
    a:hover {
      background-color: transparent;
      color: inherit;
      text-decoration: underline;
    }
  }

  p {
    line-height: $line-height-body-paragraph;
  }

  .field-label {
    font-family: var(--sp-font-secondary);
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

// Teasers
.node-teaser {
  padding-bottom: 1.1rem;
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;
  margin-bottom: 15px;

  &:last-of-type {
    border-bottom: none;
  }

  &__meta {
    color: $medium-gray;
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    font-size: 0.9rem;
    margin-top: $spacer;

    &--date {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}

.list-heading {
  @include list-heading();
}

.related-list-heading {
  border-top: 1px solid var(--sp-color-neutral-accent-one);
  margin-top: $spacer;

  h3 {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 7px;
    padding-top: 12px;
    font-weight: 600;
    text-transform: uppercase;
    word-break: break-word;
    position: relative;
  
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 4px;
      width: 150px;
      background-color: var(--sp-color-accent-one);
      left: 0;
      top: 0;
    }
  }
}

// general paragraphs spacing
section[class^="paragraph__"] {
  // height: 100%;

  &::after {
    @extend .clearfix;
  }
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

/* Define the keyframes for the delayed appearance */
@keyframes delayedAppearance {
  from { visibility: hidden; }
  to { visibility: visible; }
}

/* Define a class that applies the delayed appearance */
.delayed-appearance {
  visibility: hidden; /* Start with the element hidden */
  animation: delayedAppearance 0.1s ease-in forwards;
  animation-delay: .6s; /* Delay the start of the animation */
}

// TODO: Can we move these to just a .paragraph style? And add .paragraph to the paragraph classes? Then we don't have to account for every one of them and they are consistent.
.paragraph {
  &__wysiwyg,
  &__twocolumn,
  &__threecolumn,
  &__iconrow,
  &__media,
  &__pagelist,
  &__policylist,
  &__projectlist,
  &__scholarslist,
  &__storylist,
  &__newslist,
  &__bloglist,
  &__specialtext,
  &__eventlist,
  &__resourcelist,
  &__publicationlist {
    padding-top: $spacer * 2;
  }

  &__twocolumn,
  &__threecolumn {
    .paragraph {
      &__wysiwyg,
      &__iconrow,
      &__media,
      &__pagelist,
      &__policylist,
      &__projectlist,
      &__scholarslist,
      &__storylist,
      &__newslist,
      &__bloglist,
      &__specialtext,
      &__eventlist,
      &__resourcelist,
      &__publicationlist {
        &:first-of-type {
          padding-top: 0;
        }
      }
    }
  }

  &__wysiwyg + &__pullquote {
    margin-top: 0;
  }

  &__wysiwyg > :first-child {
    margin-top: 0;
  }

  &__wysiwyg > p + h2:first-of-type {
    margin-top: $spacer;
  }

  &__twocolumn > h2:first-of-type {
    margin-top: 0;
  }
}

// Accordions - global styles
.accordion {
  .card {
    border: none;
    border-bottom: 1px solid var(--sp-color-neutral-accent-one);
    border-radius: 0px;
  }

  &-toggle {
    font-family: var(--sp-font-secondary);
    background-color: $white;
    border: none;
    width: 100%;
    margin: auto;
    padding: 20px 0 20px 60px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.33;
    color: $body-text;
    border-bottom: none;

    &:focus {
      box-shadow: none;
      color: black;
      border: none;
      box-shadow: none;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:hover {
      background-color: $off-white;
      border-radius: unset;
    }
  }

  .questionWrapper {
    margin-left: 2.2em;
  }

  // Accordion toggle icon - change to +/-, align to right
  &-icon {
    cursor: pointer;
    position: absolute;
    top: 33px;
    left: 5px;
    width: 28px;
    height: 28px;

    &:before,
    &:after {
      background: var(--sp-color-secondary);
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
      width: 28px;
      border: none;
      transition: transform 0.1s ease;
    }

    &:after {
      transform-origin: center;
      background: var(--sp-color-secondary);
    }

    button[aria-expanded="false"] & {
      &:after {
        transform: rotate(90deg);
      }
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: var(--sp-font-primary);

    padding: $spacer $spacer * 3;
  }
}
