@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.iframe {

  @include media-breakpoint-down(lg) { 
    width: 100%;
    height: 100%;
  }
}
