@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
/*
 *  Global for card references
 */
.reference-card {
  margin: $spacer * 1 0;

  // content wrapper
  &__content {
    background-color: $white;
    padding: $spacer $spacer * 2;
    margin-left: $spacer * 2;
    margin-top: -$spacer * 4;
    position: relative;
    z-index: 150;

    // override for Blog
    &--blog,
    &--story {
      margin: 0 0 0 $spacer * (-2);
      background-color: var(--sp-color-primary);
      color: $white;
      border-bottom: solid $spacer * 2 var(--sp-color-secondary);
      border-right: solid $spacer * 2 var(--sp-color-secondary);

      a {
        color: $white;

        &:hover {
          color: $white !important;
          text-decoration: none !important;
        }
      }
    }
  }

  // title
  .heading {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--sp-color-secondary);


    // reference card heading icon
    .reference-card__icon {
      color: var(--sp-color-secondary);
      font-size: 26px;
      position: relative;
      transform: rotate(180deg);
      transition: all .5s ease-out;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      
      .reference-card__icon {
        transform: translateX(7px) rotate(180deg);
      }
    }
  }

  // meta (date, authors, news source)
  &__meta {
    font-family: var(--sp-font-secondary);
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }

  // News reference card modifier
  &__news,
  &__story {
    @include offsetBackground(var(--sp-color-secondary));
    padding: 0 $spacer * 2 $spacer * 2 0;
  }

  &__news {
    max-width: 900px;
  }

  &__faq {
    .accordion-toggle {
      font-family: var(--sp-font-secondary);
    }
  }

  // Blog reference card modifier
  &__blog,
  &__story {
    @include offsetBackground(var(--sp-color-primary));
    padding: 0 0 0 $spacer * 2;
  }
}
