@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.date-cardview {
  background-color: var(--sp-color-primary);
  padding: $spacer * 0.6 $spacer * 0.8;
  color: $white;
  display: block;
  max-width: 4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-month {
    font-family: var(--sp-font-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    display: block;
    line-height: 1.2;
  }

  &-day {
    font-family: var(--sp-font-primary);
    font-size: 1.3rem;
    display: block;
    line-height: 1.2;
    font-weight: 600;
  }
}
