@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.divider {
  margin: $spacer*2 0;
  height: 8px;
  max-width: 212px;
  background-color: $yellow;
  color: $yellow;
  border: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  opacity: 1;
}

.divider--long {
  max-width: 100%;
  height: 5px;
}