@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


// TODO: Should this deviate from other headings?? It's almost unnecessary.
.paragraph__slideshow {

  .heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.4;
    text-transform: none;
    
    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
  }

}
