@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__hero-full-width-video {

  .row {
    --bs-gutter-x: 0;
  }

  .video-player-wrapper {
    position: relative;
    max-height: 785px;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      min-height: 500px;

      iframe {
        min-height: 500px;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1000px !important;
        transform: translate(-50%, 0%);
      }
    }
  }

  .video-dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  .heading-link-wrapper {
    position: absolute;
    top: 35%;
    text-align: center;
    padding-left: 0;

    @include media-breakpoint-down(lg) {
      top: 30%;
    }
    @include media-breakpoint-down(md) {
      top: 20%;
      min-height: 500px;
    }
    @include media-breakpoint-down(sm) {
      top: 10%;
    }

    .heading {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  .video-play-pause-wrapper {
    position: absolute;
    right: 6%;
    bottom: 5%;

    .play-btn, .pause-btn {
      font-family: var(--sp-font-h1);
      background: transparent;
      padding: 0;
      color: white;
      border: none;
      text-shadow: 2px 2px 4px black;
    }

    .play-icon, .pause-icon {
      fill: white;
      filter: drop-shadow(2px 2px 4px black);
      padding-left: 5px;
      width: 20px;
      height: 20px;
      margin-top: -3px;
    }

  }

}