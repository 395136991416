@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

@import "~bootstrap/scss/carousel";

.slider {

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;

    &:hover {
      background-color: transparent;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    position: absolute;
    padding: $spacer * 1;
    width: 40px;
    height: 40px;
    background-color: $light-green;

    @include media-breakpoint-up(sm) {
      width: 60px;
      height: 60px;
    }
  }

  .carousel-control-prev-icon {
    left: 0;
    background: url("./../../../images/slideshow_prev.svg") center center
      no-repeat;
    background-size: 40px 40px;

    @include media-breakpoint-up(sm) {
      background-size: 60px 60px;
    }
  }
  .carousel-control-next-icon {
    right: 0;
    background: url("./../../../images/slideshow_next.svg") center center
      no-repeat;
    background-size: 40px 40px;

    @include media-breakpoint-up(sm) {
      background-size: 60px 60px;
    }
  }

    .carousel-inner {
  		// max-height: 80vh;
  		
      .carousel-caption {
        background-color: $white;
        color: $dark-gray;
        font-family: var(--sp-font-secondary);
        padding: $spacer $spacer * 1.5;
        bottom: 35px;
        right: 0;
        left: 76px;
        font-size: 0.9rem;
        font-weight: 600;
        text-align: left;

        p {
          margin-bottom: 0;
        }

        @include media-breakpoint-down(lg) {
          position: static;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

  .custom-indicators {
    margin-top: 1.1429em;
    font-family: var(--sp-font-secondary);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $medium-gray;
    line-height: 1.71;
    letter-spacing: 1px;
  }

  .carousel-indicators {
    display: none;
    li {
      background-color: $dark-gray;
    }
  }

  .image {
    min-height: 400px;
  }
}

.carousel-control-prev:focus-visible .carousel-control-prev-icon,

.carousel-control-next:focus-visible .carousel-control-next-icon{

  outline: 2px solid #0577B1;

  outline: 5px auto -webkit-focus-ring-color;

}

 