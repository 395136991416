@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      text-decoration: underline;
      color: var(--sp-color-secondary);

      &:hover {
        text-decoration: none;
      }
    }

    a[name] {
      text-decoration: none;
      color: var(--sp-color-primary);

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.paragraph__wysiwyg
.body, 
 main {
  p {
    a {
      color: var(--sp-color-link);
      border-bottom: 1px solid #ccc;

      &:hover {
        color: var(--sp-color-primary);
        border-bottom: 1px solid #012169;
        text-decoration: none;
      }
    }
  }
}

.paragraph__wysiwyg,
.body,
.card-body,
.main-content,
.resource-wrapper {
  @include clearfix;

  .media {
    display: block;
    margin-bottom: $spacer;
    max-width: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  // CKEditor media customizations
  .caption {
    width: auto;

    & > figcaption {
      display: block;
      max-width: fit-content;
      margin-bottom: $spacer;
      margin-top: -$spacer*0.5;
      color: $medium-gray;
    }
  }

  .media--view-mode-full-column-width {
    width: 100%;

    &.player-wrapper {
      margin-top: $spacer*2;
      margin-bottom: $spacer*2;
    }
  }

  .media--type-image ~ ul {
    display: grid;
  }

  @include media-breakpoint-up(md) {
    figure.caption,
    article.media {
      width: auto;

      &.align-right {
        float: right !important;
        margin-left: $spacer*2;
      }

      &.align-left {
        float: left !important;
        margin-right: $spacer*2;
      }

      &.align-center, &.align-center figcaption {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      &.media--view-mode-_-2-column-width {
        width: 50%;

        & + figcaption {
          width: 50%;
        }

        &.player-wrapper {
          padding-top: 28.25%;
        }
      }

      &.media--view-mode-_-3-column-width {
        width: 33.33%;

        & + figcaption {
          width: 33.33%;
        }

        &.player-wrapper {
          padding-top: 22.25%;
        }
      }
    }

    figure.caption > article.media {
      &.align-right,
      &.align-left,
      &.align-center,
      &.media--view-mode-_-2-column-width,
      &.media--view-mode-_-3-column-width {
        width: 100%;
        float: none;

        & + figcaption {
          width: 100%;
        }

        &.player-wrapper {
          padding-top: 60%;
        }
      }
    }
  }

  .table-wrapper {
    overflow-x: auto;
  }
}

// Tables
table {
  font-family: var(--sp-font-secondary);
  border: 1px solid #ccc;
  font-size: 0.9rem;
  margin-bottom: $spacer;

  caption {
    color: $white;
    background-color: var(--sp-color-primary);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    caption-side: top;
    margin-bottom: 0;
  }

  a {
    color: var(--sp-color-link);
  }

  th, td, caption {
    padding: $spacer * 0.5 $spacer;
    border: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: none;
  }

  th {
    background-color: var(--sp-color-primary);
    color: $white;
    font-weight: 600;

    h2, h3, h4, h5, h6 {
      color: $white;
    }

    p {
      margin: 0;
    }

    a {
      color: #0797e0;

      &:hover {
        background-color: #0797e0;
      }
    }
  }

  tr {
    background-color: #FFF;
  }

  tr:nth-child(odd) {
    background-color: #F5F5F5;
  }

  td {
    p, ol, ul {
      margin-bottom: 0;
    }
  }

}