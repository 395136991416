@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.image,
img {
  max-width: 100%;
  height: auto;
}

.image--rounded-circle {
  @extend .rounded-circle !optional;
  width: 120px;
  height: 120px;
}
