@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
[data-reach-skip-nav-link] {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}