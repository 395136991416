@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.resource {
  &-wrapper {
    .eyebrow {
      margin-bottom: 0;
    }

    .heading {
      margin-top: 0;
    }
  }  

  &-categories {
    font-family: var(--sp-font-secondary);
    -webkit-font-smoothing: antialiased;
    margin-top: $spacer*0.8;
  }

  &-date {
    font-style: italic;
    margin-bottom: $spacer;

    * {
      display: inline;
    }
  }

  &-media {
    margin-bottom: $spacer;
  } 
}

.node-teaser.resource {
  padding: $spacer*1.5 0; 
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;
  margin-bottom: 0;
  
  @include media-breakpoint-up(md) {
    display: flex;
  }

  .image-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 25%;
      padding-right: $spacer;
    }
  }

  .resource-featured-image {
    max-width: 250px;
    @include media-breakpoint-up(md) {
      max-width: 168px;
    }    
  }

  .summary {
    margin-top: $spacer*0.8;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .heading--teaser {
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }

  .resource__meta--date {
    display: inline;
    font-style: italic;

    * {
      display: inline;
      font-style: italic;
    }
  }

  h4 {
    margin-top: 0;
  }

} 

.search-results {
  .node-teaser.resource {
    &:last-of-type {
      border-bottom: solid 1px var(--sp-color-neutral-accent-one);
    }
  }
}

.paragraph__resourcelist,
.paragraph__content-reference {
  .node-teaser.resource .image-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px !important;
      padding-right: $spacer;
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .node-teaser.resource {
    display: flex;
    
    @include media-breakpoint-down(xl) {
      flex-direction: column;

      .heading--teaser {
        margin-top: 1rem;
      }
    }
  }
}

.node-teaser.resource.display-in-grid {
  @include media-breakpoint-up(md) {
    display: block;
  }

  .heading--teaser {
    padding-top: 10px;
  }

  @include media-breakpoint-up(md) {
    .image-wrapper {
      width: unset !important;
    }
  }

  .resource-featured-image {
    max-width: 100%;
  }

}

/* Resource list grid border left */
@include media-breakpoint-up(md) {
  .paragraph__resourcelist {

    .col-md-4 {
      .display-in-grid {
        .image-wrapper {
          padding-bottom: 20px;
        }

        .text-wrapper {
          border-left: 1px solid var(--sp-color-neutral-accent-one);
          padding-left: 20px;

          .heading {
            padding-top: 0;
          }
        }
      }
    }

  }
}