@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.policylist {
  &__title {
    @include heading-base;
  }

  &__wrapper {
    flex-direction: column;
    display: flex;
    margin-bottom: $spacer * 4;
  }

  &__col {
    width: 100%;

    @include media-breakpoint-up(lg) {
      &:first-of-type {
        width: 100%;
      }
    }
  }
}

div[class^="col"] .bloglist {
  &__wrapper {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }

  &__col {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}

.node-teaser__policy {
  padding: $spacer*1.5 0; 
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;
  margin-bottom: 0;
}