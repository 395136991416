@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.event-card {
  padding: $spacer;
  border: solid 4px var(--sp-color-neutral-accent-one);
  margin-top: $spacer*3;
  margin-right: $spacer*1.5;

  &__titlewrapper {
    .eyebrow {
      margin-bottom: 0;
    }
  }

  svg {
    color: var(--sp-color-secondary);
    width: 24px;
    height: 24px;
    vertical-align: middle;

  }

  .date {
    margin-top: -3rem;
    margin-bottom: $spacer;
  }

  .cardHeaderWrapper .heading {
    text-transform: initial;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 0;
  }

  &__status {
    color: var(--sp-color-accent-two);
    font-style: italic;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__time,
  .event-card__location {
    font-family: var(--sp-font-secondary);
    color: $dark-gray;
  }

  .field-container {
    display: grid;
    grid-template-columns: 24px 1fr;
    width: fit-content;
    column-gap: 8px;
    margin-bottom: 0.5em;
    word-break: break-word;
    font-family: var(--sp-font-secondary);
    color: $dark-gray;

    p {
      margin: 0;
    }

    .date,
    .time {
      max-width: 210px;
      line-height: 24px;
    }
  }
}

 // Teaser modifier - used on the events page and nested inside 2- and 3-columns PB
.paragraph__twocolumn,
.paragraph__threecolumn {
  .event-card-wrapper {
    border: solid 8px var(--sp-color-neutral-accent-one);
    display: block;
  }

  .event-card {
    border: none;
    border-bottom: solid 1px var(--sp-color-neutral-accent-one);
    margin: 0;
    padding: $spacer;
    max-width: 100%;
      
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: 100%;
    }

    .cardHeaderWrapper {
      margin-bottom: $spacer;

      @include media-breakpoint-up(md) {
        display: flex;
        grid-template-columns: 100px 1fr;
        margin-bottom: 0;
      }
    }

    &__cardInfoWrapper {
      @include media-breakpoint-up(md) {
        padding-left: $spacer;
      }
    }

    .date {
      margin-top: 0;
    }

    .heading {
      text-transform: initial;
      margin-top: 0;
    }
  }
}

.event-card--teaser {
  border: none;
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  margin-top: $spacer;

  .cardHeaderWrapper {
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      display: inline-grid;
      grid-template-columns: 100px 1fr;
    }
  }

  .date {
    margin-top: 0;
  }

  .heading {
    text-transform: initial;
    margin-top: 0;
  }
}