@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.utility_menu_wrapper {
  background: var(--sp-color-header-secondary);
  display: flex;
  flex-direction: column;
  width: 100%;

  &::before {
    content: '';
    margin-bottom: $spacer;
    border-top: 1px solid var(--sp-color-neutral-accent-one);
  }

  @include media-breakpoint-up(lg) {
    height: 48px;
    flex-direction: row;
    border-top: none;
    background: transparent;
    width: auto;
    margin-top: 0.9rem;
  }
}

.navbar-nav .utilitymenu li a.nav-link {
  color: var(--sp-color-menu-utility-link-mobile);
  font-weight: 600;
  padding: $spacer*0.5 0;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up(lg) {
    padding: 0;
    color: var(--sp-color-menu-utility-link);
  }

  &:hover,
  &:focus {
    @include media-breakpoint-up(lg) {
      text-decoration: underline;
    }
  }

  &.active {
    color: var(--sp-color-menu-utility-link-mobile);

    @include media-breakpoint-up(lg) {
      color: var(--sp-color-menu-utility-link);
    }
  }
}

.utilitymenu {
  justify-content: flex-start;

  @include media-breakpoint-up(lg) {
    height: 48px;
    justify-content: flex-end;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: stretch;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    li {
      font-family: var(--sp-font-secondary);
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
      padding: 0 $spacer*0.8;

      &:last-of-type {
        padding-right: 0;
      }

      @include media-breakpoint-down(lg) {
        padding: 0;
      }

      a {
        color: $white;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
          color: $white;
        }
      }
    }
  }
}
