@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__content-reference {

  h5 {
    margin-top: 0;
  }

  .faq-teaser {
    .questionWrapper {
      font-size: 1.3rem;
      text-transform: initial;
      color: $dark-gray;
      font-family: var(--sp-font-secondary);
    }
  }

  .node-teaser__project {
    border-bottom: none;
  }
}
