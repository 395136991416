@import "/usr/src/app/general/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__content-reference {
  .person-card {
    h3  {
      margin-top: 0;
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__content-reference {
    .person-card {
      @include media-breakpoint-up(md) {
        grid-template-columns: 100%;
      }

      h3  {
        margin-top: $spacer*0.5;
      }
    }
  }
}

.person-card {
  padding: $spacer*1.5 0;
  border-top: solid 1px var(--sp-color-neutral-accent-one);
  width: 100%;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 18% 82%;
  }

  &:first-of-type {
    border-top: none;
		padding-top: 0;
  }

  h3 {
    line-height: 1.3;
    margin-top: $spacer*0.5;

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      margin-top: 0;
    }
  }

  &-position {
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5 !important;
  }

  &-image {
    padding-bottom: $spacer; 

    @include media-breakpoint-up(md) {
      padding-right: $spacer*2;
      padding-bottom: 0;
    }

    img {
      width: 100%;
			max-width: 200px;
    }
  }

  svg {
    color: var(--sp-color-secondary);
    margin-right: 0.3em;
    width: 24px;
    height: 24px;
  }

  &-email,
  &-phone {
    font-style: italic;
    margin-bottom: $spacer*0.7;
  }

  &-phone {
    a {
      color: $body-text;
    }
  }

  &__content {
    width: 100%;
  }
}

.personlist__wrapper {
  display: flex;
  flex-direction: column;

  &--grid {
    flex-direction: row;
    flex-wrap: wrap;  
    
    .person-card, 
    .person-card:first-of-type {
      width: 100%;
      padding-top: 0;
      border-top: none;

      @include media-breakpoint-up(md) {
        display: block;
        width: 33%;
        padding-right: 2%;
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }

      h3 {
        font-size: 1.2rem;
      }

      .person-card-position {
        font-size: 1rem;
      }
    }
  }
}

.paragraph__personlist {
  margin-bottom: $spacer*2;

  &:first-of-type {
    margin-top: $spacer*2;
  }

  h3 {
    text-transform: initial;
  }
}
